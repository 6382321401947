import { StaticQuery, graphql } from "gatsby";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ posts, site }) => {
      return children({ posts, site });
    }}
  />
);
const query = graphql`
  query ArchivePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      blogPageTitle
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`;
