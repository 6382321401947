import * as styles from "./BlogItemCardGrid.module.scss";
import { pageTitleStyle } from "./typography.module.scss";

import BlogItemCard from "./BlogItemCard";
import { FC } from "react";
import { SanityPost } from "../../graphql-types";
import Container from "./Container";
import { cn } from "../lib/helpers";
import LinesLeft from "../images/lines-left.svg";
import LinesRight from "../images/lines-right.svg";
interface BlogItemCardGridProps {
  title: string;
  nodes: SanityPost[];
}
const BlogItemCardGrid: FC<BlogItemCardGridProps> = ({ title, nodes }) => {
  return (
    <div className={styles.root}>
      <Container>
        {title && <h2 className={cn(pageTitleStyle, styles.title)}>{title}</h2>}
        <div className={styles.gridWrapper}>
          <div className={styles.linesContainer}>
            <div className={cn(styles.line)}>
              <LinesLeft />
            </div>
            <div className={cn(styles.line)}>
              <LinesRight />
            </div>
          </div>
          <ul className={styles.grid}>
            {nodes &&
              nodes.map((node) => (
                <li key={node.id}>
                  <BlogItemCard {...node} />
                </li>
              ))}
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default BlogItemCardGrid;
