import BlogItemCardGrid from "../components/BlogItemCardGrid";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/Layout";
import PageHelmet from "../components/PageHelmet";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";

import QArchive from "../queries/QArchive";
import {
  SanityPost,
  SanityPostEdge,
  SanitySiteSettings,
} from "../../graphql-types";

interface ArchivePageProps {
  posts: SanityPostEdge[];
  site: SanitySiteSettings;
}

const ArchivePage = (props) => {
  const { errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const renderArchivePage = ({ posts, site }: ArchivePageProps) => {
    const postNodes: SanityPost[] = mapEdgesToNodes(posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture);
    return (
      <Layout>
        <PageHelmet title={site.blogPageTitle} />
        {postNodes && postNodes.length > 0 && (
          <BlogItemCardGrid title={site.blogPageTitle} nodes={postNodes} />
        )}
      </Layout>
    );
  };

  return <QArchive>{renderArchivePage}</QArchive>;
};

export default ArchivePage;
